
import { defineComponent, ref } from "vue";

export default defineComponent({
  props: {
    label: {
      type: String,
      required: true,
      default: "Pet Type",
    },
    formProp: {
      type: String,
      required: true,
      default: "Pet Type",
    },
    modelValue: {
      type: Number || null,
      required: true,
      default: null,
    },
    placeHolder: {
      type: String,
      required: false,
      default: "",
    },
    tooltipMessage: {
      type: String,
      required: false,
      default: "",
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    isTooltip: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  setup(_, context) {
    const updateValue = (value) => {
      if(value) context.emit("update:modelValue", parseInt(value));
    };

    const pet_types = ref([
      {
        value: 1,
        label: 'Cat'
      },
      {
        value: 2,
        label: 'Dog'
      },
    ])
    return {
      pet_types,
      updateValue,
    };
  },
});
