
import { computed, defineComponent, onMounted, ref } from "vue";
import { genders } from "@/core/utils";

export default defineComponent({
  props: {
    label: {
      type: String,
      required: true,
      default: "Gender",
    },
    formProp: {
      type: String,
      required: true,
      default: "gender",
    },
    modelValue: {
      type: String,
      required: true,
      default: "",
    },
    placeHolder: {
      type: String,
      required: false,
      default: "",
    },
    tooltipMessage: {
      type: String,
      required: false,
      default: "",
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    isTooltip: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  setup(_, context) {


    const updateValue = (event) => {
      search.value = ''
      context.emit("update:modelValue", event);
      context.emit("change", event);
    };

    const search = ref('')

    const filtered_genders = computed(() => {
      if(genders) {
        return genders.filter(obj => 
        obj.label.toString().toLowerCase().includes(search.value.toLowerCase()))
      }
    })

    const isMobile = window.innerWidth < 960 ? true : false

    const gender_id = ref('')
    onMounted(() => {
      if(isMobile) {
        const gender_teleport = document.getElementsByClassName('gender_teleport')[0]
        gender_id.value = gender_teleport.id
      }
    })

    const gender_ref = ref()

    const alertgender = () => {
      // setTimeout(() => {
      //   gender_ref.value.focus()
      // }, 500);
    }


    return {
      updateValue,
      alertgender,
      gender_ref,
      genders,
      filtered_genders,
      search,
      isMobile,
      gender_id
    };
  },
});
