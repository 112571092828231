

import { store } from "@/store";
import { currency } from "@/core/functions/currency";
import Quotes from "@/core/services/Quotes";
import router from "@/router";
import axios from 'axios';
import { Links } from '@/core/enum/Links';
import { ElLoading } from "element-plus";
import { defineComponent, onMounted, computed, ref, toRef } from "vue";

export default defineComponent({
    setup(_) {
        const domain = ref(process.env.VUE_APP_ASSETS_DOMAIN)
        const url = ref(process.env.VUE_APP_INSURANCE_ASSETS_URL)
        const goBakUrl = process.env.VUE_APP_PAYMENT_GATEWAY_URL

        let thirdParty = computed(() => {
            return store.state.quotes.thirdParty
        });

        const language = computed(() => {
            return window.localStorage.getItem('language');
        })

        onMounted(async () => {
            const lead_id: any = window.localStorage.getItem('lead_id');
            
            const loading = ElLoading.service({
                lock: true,
                text: 'Loading',
                background: 'rgba(0, 0, 0, 0.9)',
            })
            const payload = {
                language: window.localStorage.getItem('language'),
                lead_id: window.localStorage.getItem('lead_id')
            }
            await Quotes.getQuotes(payload);
            loading.close()
        })
        
        const BuyNow = async (insurance_id) => {
            const loading = ElLoading.service({
                lock: true,
                text: 'Loading',
                background: 'rgba(0, 0, 0, 0.9)',
            })
            const lead_id: any = window.localStorage.getItem('lead_id');
            const payload = {
                pet_lead_id: lead_id,
                insurance_policy_master_id: insurance_id,
            }
            loading.close()
            const data = await axios.post(Links.PROCEED_BUY, payload);
            const route = router.resolve({ name: 'ThankYou'})
            window.location.href = route.href;
        }

        return {
            thirdParty,
            domain,
            url,
            language,
            goBakUrl,
            BuyNow
        }
    }
});
