
import { computed, defineComponent, toRef, watchEffect } from "vue";
import { months, month_mobiles } from "@/core/utils";
import moment from "moment";

export default defineComponent({
  props: {
    label: {
      type: String,
      required: true,
      default: "Date of Birth",
    },
    formProp: {
      type: String,
      required: true,
      default: "dob",
    },
    modelValue: {
      type: String,
      required: true,
      default: "",
    },
    dayValue: {
      type: String,
      required: true,
      default: "",
    },
    dayProp: {
      type: String,
      required: true,
      default: "dob_day",
    },
    dayPH: {
      type: String,
      required: true,
      default: "Day",
    },
    monthValue: {
      type: String,
      required: true,
      default: "",
    },
    monthProp: {
      type: String,
      required: true,
      default: "dob_month",
    },
    monthPH: {
      type: String,
      required: true,
      default: "Month",
    },
    yearValue: {
      type: String,
      required: true,
      default: "",
    },
    yearProp: {
      type: String,
      required: true,
      default: "dob_year",
    },
    yearPH: {
      type: String,
      required: true,
      default: "Year",
    },
    isTooltip: {
      type: Boolean,
      required: false,
      default: false,
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    tooltipMessage: {
      type: String,
      required: false,
      default: "",
    },
    isDOB: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  setup(_, context) {
    const language = computed(() => {
      return window.localStorage.getItem("language");
    });

    const myDate = toRef(_, "modelValue");
    const myDay = toRef(_, "dayValue");
    const myMonth = toRef(_, "monthValue");
    const myYear = toRef(_, "yearValue");

    const selectedDay = (event) => {
      context.emit("update:dayValue", event);
    };

    const selectedMonth = (event) => {
      context.emit("update:monthValue", event);
    };

    const selectedYear = (event) => {
      context.emit("update:yearValue", event);
    };

    watchEffect(() => {
      const day = myDay.value.toString().length == 1 ? `0${myDay.value}` : myDay.value;
      const month =
        myMonth.value.toString().length == 1 ? `0${myMonth.value}` : myMonth.value;
      const dob = moment(`${myYear.value}-${month}-${day}`).format('YYYY-MM-DD');
      myDate.value = dob;
      context.emit("update:modelValue", dob);
    });

    return {
        selectedYear,
      selectedMonth,
      selectedDay,
      myDate,
      myDay,
      myMonth,
      myYear,
      months,
      month_mobiles,
      language,
    };
  },
});
