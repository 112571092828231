const ar = {
  test: "الإنجليزية",
  personal: "التفاصيل",
  details: "الشخصية",
  declaration: "تصريح",
  compare: "قارن",
  quotes: "العروض",
  compare_quotes: 'قارن عروض الأسعار',  
  purchase: "الشراء",
  insurance: "التأمين",
  personal_details: "التفاصيل الشخصية",
  will_take: "تستغرق العملية أقل من 1 دقيقة",
  pet_name:"اسم الحيوان الأليف",
  pet_name_placeholder:"اسم الحيوان الأليف",
  pet_name_tooltip:"اسم الحيوان الأليف",
  full_name: "اسمك بالكامل",
  full_name_placeholder: "اسمك بالكامل",
  full_name_tooltip: "اسم الحيوان الأليف",
  phone_number: "رقم الهاتف",
  phone_number_placeholder: "رقم الهاتف",
  insured_type: "نوع المؤمن",
  city: "المدينة",
  city_tooltip: "المدينة",
  date_of_birth: "تاريخ الولادة",
  date_of_birth_tooltip: "تاريخ الولادة",
  day: "اليوم",
  month: "الشهر",
  year: "السنة",
  nationality: "الجنسية",
  yes: "نعم",
  no: "لا",
  salary: "هل راتبك أكثر من 4000 درهم شهريًا؟",
  expected_insurance: "التاريخ المتوقع لبدء التأمين",
  expected_insurance_tooltip: "التاريخ المتوقع لبدء التأمين",
  email_address: "البريد الإلكتروني",
  email_address_placeholder: "عنوان البريد الإلكتروني",
  email_address_tooltip: "عنوان البريد الإلكتروني",
  gender: 'الجنس',
  pet_gender: 'جنس الحيوان الأليف',
  pet_type: 'نوع الحيوان الأليف',
  pet_age_tooltip:"أدخل العمر الحالي لحيوانك الأليف بالسنوات أو بالأشهر .",
  pet_age: 'عمر الحيوان الأليف',
  member_date_of_birth: "الرجاء تحديد تاريخ ميلاد العضو",
  relationship: 'الصلة',
  member_full_name: 'الاسم الكامل',
  member_details: 'أدخل تفاصيل العضو',
  get_insured: "احصل على تأمين",
  car_insurance: "تأمين السيارة",
  health_insurance: "التأمين الصحي",
  expat_insurance: 'تأمين المغتربين',
  expat_health_insurance: 'التأمين الصحي للمغتربين ',
  pet_insurance: 'تأمين الحيوانات الأليفة',
  travel_insurance: 'تأمين السفر',
  pet: 'تأمين الحيوانات الأليفة',
  home_insurance: "تأمين المنزل",
  bike_insurance: "تأمين الدراجة النارية",
  other_insurance: "تأمينات أخرى",
  renew_a_policy: "تجديد التأمين",
  company: "الشركة",
  about: "نبذة عنّا",
  insurance_partners: "شركاء التأمين",
  hr_solutions: "حلول الموارد البشرية",
  blog: "المدونة",
  quick_links: "روابط سريعة",
  privacy_page: "صفحة الخصوصية",
  tac: "الأحكام والشروط",
  ami: "تأمين المنارة",
  edarat: "E-DARAT",
  support: "الدعم",
  help_center: "مركز المساعدة",
  contact: "اتصل بنا",
  cancellation: "إلغاء التأمين",
  calculator: "الحاسبات",
  car_loan: "قرض السيارة",
  mortgage: "القرض العقاري",
  insurance_calc: "حاسبة التأمين",
  mail: 'البريد الإلكتروني',
  head_office: 'المكتب الرئيسي',
  need_assistance: 'طلب المساعدة',
  call_us: 'أو اتصل بنا على',
  support_center: 'طلب المساعدة',
  corporate: 'الشركات',
  solution: 'حلول',
  address: 'سنتر حدائق الباشا، بلوك ب، الطابق الأول، جسر الباشا. لبنان',
  disclaimer: 'تنصل',
  company_of: 'إحدى شركات ',
  proceed: "أكمل",
  wait: "الرجاء الانتظار",
  financial_limitation:"الحد المالي بالفريش دولار الأمريكي",
  total_premium: "إجمالي الأقساط",
  req_call_back: "طلب إعادة الاتصال",
  buy_now: "اشترِ الآن",
  thank_you: 'شكرًا لك',
  callback_thank_you_msg: 'شكرًا لاستفسارك. سيتصل بك فريقنا في أقرب وقت ممكن.',
  no_quotes_manual_quote_title: 'للحصول على عرض أسعار مباشر، تواصل معنا على الرقم المجاني <span dir="ltr">01 481 565</span> أو ',
  go_back: 'العودة',
  go_back_home: 'العودة إلى الصفحة الرئيسية',
  disclaimer_text:'Premium Choice Marketing LLC office 1406, Damac Smart Heights, Barsha Heights, Dubai, UAE is the owner of the brand name “ BuyAnyInsurance.com". Insurance is provided by Chedid Insurance Brokers SAL.',
  cat:'قطة',
  dog:'كلب',
  your_cover: 'التغطية',
  corporate_solution: 'حلول الشركات',
  corporate_solution_img: 'حلول <br> الشركات',
  select: 'Select',
  male: 'Male',
  Female: 'Female',
  google_map: 'خرائط جوجل',
  copyrights_footer: 'التأمين موضوع طلب. © حقوق الطبع والنشر - DATE_FOOTER BuyAnyInsurance.com.',
  we_cannot_process: 'لا يمكننا معالجة طلبك أون لاين، سيتصل بك فريقنا قريبًا.',
  no_quotes_manual_quote_title: 'للحصول على عرض سعر يدوي اتصل بنا على <span dir="ltr">01 481 565</span>',
  notes: 'ملاحظات',
  text_your:'لك',
  text_cover:'التغطية',
  faqs: "الأسئلة المتداولة",
};

export default ar;
