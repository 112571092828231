
import { store } from "@/store";
import { computed, defineComponent, toRef, watchEffect } from "vue";

export default defineComponent({
  props: {
    isRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: true,
      default: "Salary",
    },
    modelValue: {
      type: Boolean,
      required: true,
    },
    member: {
      type: Array,
      required: false,
      default: []
    },
    id: {
      type: String,
      required: true,
      default: "a",
    },
    isDeclaration: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(_, context) {
    const myValue = toRef(_, "modelValue");
    const member = toRef(_, "member");

    const selectedValue = computed(() => {
      return myValue.value = !myValue.value
    })

    const selectedMember = (event) => {
      context.emit("update:member", event);
    };

    const members = computed(() => {
      return store.state.personals.members;
    })

    watchEffect(() => {
      // members.value.map(x => {
      //   return member.value.filter(y => y !== x.value)
      // })
      // console.log(member.value)
    })

    const changeValue = () => {
      context.emit("update:member", []);
      context.emit('setValue')
    }

    return {
      member,
      members,
      myValue,
      selectedValue,
      selectedMember,
      changeValue
    };
  },
});
