
import { computed, defineComponent, ref, onMounted } from "vue";
import { petage } from "@/core/utils";

export default defineComponent({
  props: {
    label: {
      type: String,
      required: true,
      default: "Gender",
    },
    formProp: {
      type: String,
      required: true,
      default: "Select Pet Age",
    },
    modelValue: {
      type: String,
      required: true,
      default: "Select Pet Age",
    },
    placeHolder: {
      type: String,
      required: false,
      default: "",
    },
    tooltipMessage: {
      type: String,
      required: false,
      default: "",
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    isTooltip: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  setup(_, context) {


    const updateValue = (event) => {
      search.value = ''
      context.emit("update:modelValue", event);
      context.emit("change", event);
    };

    const search = ref('')

    const filtered_ages = computed(() => {
      if(petage) {
        return petage.filter(obj => 
        obj.label.toString().toLowerCase().includes(search.value.toLowerCase()))
      }
    })

    const isMobile = window.innerWidth < 960 ? true : false

    const age_id = ref('')
    onMounted(() => {
      if(isMobile) {
        const age_teleport = document.getElementsByClassName('age_teleport')[0]
        if(age_teleport)
        age_id.value = age_teleport.id
      }
    })

    const age_ref = ref()

    const alertage = () => {
      // setTimeout(() => {
      //   age_ref.value.focus()
      // }, 500);
    }


    return {
      updateValue,
      alertage,
      petage,
      age_ref,
      filtered_ages,
      search,
      isMobile,
      age_id
    };
  },
});
