
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import { formPersonalDetails } from "@/store/stateless";
import { emirates } from "@/core/utils";
import { store } from "@/store";
import Validations from "@/core/validations";
import router from '@/router';
import personalDetails from "@/core/services/PersonalDetails";
import moment from 'moment'

export default defineComponent({
  setup(_) {
    const customer = computed(() => {
      return store.state.personals.customer;
    });

    const loading = computed(() => {
      return store.state.personals.loading
    })

    const language = computed(() => {
      return window.localStorage.getItem('language');
    })
    onMounted(async() => {
      // customer.value.customer_source_url = router.currentRoute.value.fullPath;
      // customer.value.btm_source = router.currentRoute.value.query && router.currentRoute.value.query.btm_source ?  router.currentRoute.value.query.btm_source.toString() : '';
      customer.value.utm_source = router.currentRoute.value.query?.utm_source ?  router.currentRoute.value.query.utm_source.toString() : 'website';
      customer.value.utm_medium = router.currentRoute.value.query?.utm_medium ?  router.currentRoute.value.query.utm_medium.toString() : 'website';
      customer.value.utm_campaign = router.currentRoute.value.query?.utm_campaign ?  router.currentRoute.value.query.utm_campaign.toString() : 'nc';
      customer.value.utm_content = router.currentRoute.value.query?.utm_content ?  router.currentRoute.value.query.utm_content.toString() : 'website';
      
      customer.value.leads.utm_source = router.currentRoute.value.query?.utm_source ?  router.currentRoute.value.query.utm_source.toString() : 'website';
      customer.value.leads.utm_medium = router.currentRoute.value.query?.utm_medium ?  router.currentRoute.value.query.utm_medium.toString() : 'website';
      customer.value.leads.utm_campaign = router.currentRoute.value.query?.utm_campaign ?  router.currentRoute.value.query.utm_campaign.toString() : 'nc';
      customer.value.leads.utm_content = router.currentRoute.value.query?.utm_content ?  router.currentRoute.value.query.utm_content.toString() : 'website';
      const uuid = router.currentRoute.value.query?.uuid ? router.currentRoute.value.query.uuid : window.localStorage.getItem("uuid");
      if(uuid) {
        customer.value.uuid = router.currentRoute.value.query?.uuid ? router.currentRoute.value.query.uuid.toString() : window.localStorage.getItem("uuid");
        const result = await personalDetails.getCustomerDetails(customer.value)
      }  
    })
    
    const rules = reactive({
      customer_name: [{ validator: Validations.names, trigger: ["blur", "change"] }],
      customer_phone: [{ validator: Validations.phone_number, trigger: ["blur", "change"] }],
      customer_email: [{ validator: Validations.email, trigger: ["blur", "change"] }],
      'leads.pet_name': [{ validator: Validations.pet_name, trigger: ["blur", "change"] }],
      'leads.pet_age': [{ validator: Validations.pet_age, trigger: ["blur", "change"] }],
      'leads.pet_type': [{ validator: Validations.pet_type, trigger: ["blur", "change"] }],
      'leads.pet_gender': [{ validator: Validations.gender, trigger: ["blur", "change"] }],
    });

    return {
      loading,
      formPersonalDetails,
      emirates,
      customer,
      language,
      rules
    };
  },
});
