const ar = {
  test: "English",
  personal: "Personal",
  details: "Details",
  declaration: "Declaration",
  compare: "Compare",
  quotes: "Quotes",
  compare_quotes: "Compare Quotes",  
  purchase: "Purchase",
  insurance: "Insurance",
  personal_details: "Personal Details",
  will_take: "This will only take 1 minute!",
  pet_name:"Name of Pet",
  pet_name_placeholder:"Name of Pet",
  pet_name_tooltip:"this is a test tooltip english",
  full_name: "Full Name",
  full_name_placeholder: "Full Name", 
  full_name_tooltip: "this is a test tooltip english",
  phone_number: "Phone Number",
  phone_number_placeholder: "Phone Number", 
  insured_type: "Insured Type",
  city: "City",
  city_tooltip: "City",
  date_of_birth: "Date of Birth",
  date_of_birth_tooltip: "Date of Birth",
  day: "Day",
  month: "Month",
  year: "Year",
  nationality: "Nationality",
  yes: "Yes",
  no: "No",
  salary: "Is your salary more than $4,000 per month?",
  expected_insurance: "Expected Insurance Start Date",
  expected_insurance_tooltip: "Expected Insurance Start Date",
  email_address: "Email",
  email_address_placeholder: "Email address",
  email_address_tooltip: "Email address",
  gender: 'Gender',
  pet_gender: 'Pet Gender',
  pet_age: 'Pet Age',
  pet_type: 'Pet Type',
  pet_age_tooltip:"Enter your pet's current age in years or months.",
  member_date_of_birth: "Please select member's date of birth",
  relationship: 'Relationship',
  member_full_name: 'Full Name',
  member_details: 'Enter member details',

  get_insured: "Get Insured",
  car_insurance: "Car Insurance",
  health_insurance: "Health Insurance",
  expat_insurance: 'Expat Insurance',
  expat_health_insurance:'Expat Health Insurance',
  pet_insurance: 'Pet Insurance',
  travel_insurance: 'Travel Insurance',
  pet: 'Pet',
  home_insurance: "Home Insurance",
  bike_insurance: "Bike Insurance",
  other_insurance: "Other Insurance",
  renew_a_policy: "Renew a Policy",
  company: "Company",
  about: "About",
  insurance_partners: "Insurance Partners",
  hr_solutions: "HR Solutions",
  blog: "Blog",
  quick_links: "Quick links",
  privacy_page: "Privacy Page",
  tac: "Terms & Conditions",
  ami: "Al Manarah insurance",
  edarat: "E-DARAT",
  support: "Support",
  help_center: "Help Center",
  contact: "Contact",
  cancellation: "Cancellation",
  calculator: "Calculators",
  car_loan: "Car Loan",
  mortgage: "Mortgage",
  insurance_calc: "Insurance Calc",
  mail: "Email",
  head_office: "Head Office",
  need_assistance: "NEED ASSISTANCE",
  call_us: "or Call us on",
  support_center: "Visit Support Center",
  corporate: "Corporate",
  solution: "Solutions",
  address: 'Al Bacha Gardens Center, Block B, 1st floor, Jisr el Bacha, Lebanon',
  disclaimer: 'Disclaimer',
  company_of: 'A company of',
  proceed: "Proceed",
  wait: "Please wait...",
  financial_limitation:"Financial limitation in Fresh USD",
  total_premium: "Total Premium",
  req_call_back: "Request Call Back",
  buy_now: "Buy Now",
  thank_you: 'Thank You',
  callback_thank_you_msg: 'Thank you for your enquiry. Our team will contact you shortly.',
  no_quotes_manual_quote_title: 'For a manual quotation, please contact us on 01 481 565 or ',
  go_back: 'Go Back',
  go_back_home: 'Go back to home page',
  disclaimer_text:'Premium Choice Marketing LLC office 1406, Damac Smart Heights, Barsha Heights, Dubai, UAE is the owner of the brand name “ BuyAnyInsurance.com". Insurance is provided by Chedid Insurance Brokers SAL.',
  cat:'Cat',
  dog:'Dog',
  your_cover: 'Your Cover',
  corporate_solution: 'Corporate Solutions',
  corporate_solution_img: 'Corporate <br> Solutions',
  select: 'Select',
  male: 'Male',
  female: 'Female',
  google_map: 'Google Map',
  copyrights_footer: 'Insurance is the subject matter of solicitation. © Copyright-DATE_FOOTER BuyAnyInsurance.com.',
  we_cannot_process: 'We cannot process your application online. Our team will contact you shortly.',
  no_quotes_manual_quote_title: 'For a manual quotation, please contact us on 01 481 565 or ',
  notes: 'Note',
  text_your:'Your',
  text_cover:'Cover',
  faqs: 'FAQs',
};

export default ar;
