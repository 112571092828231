
import { defineComponent } from "vue";

export default defineComponent({
  name: 'DefNav',
  setup() {
          const redirect = async(val, currentRoute='') => {
            
            if(currentRoute == 'PersonalDetails' && val == 'ViewQuotes'){
              return false;
            }

            let urlParams = {}

              if(window.localStorage.getItem('uuid')){
                urlParams['uuid'] = window.localStorage.getItem('uuid');
              }
              if(window.localStorage.getItem('leadId')){
                urlParams['leadId'] = window.localStorage.getItem('leadId');
              }
              if(window.localStorage.getItem('source')){
                urlParams['source'] = window.localStorage.getItem('source');
              }

              const currentParams = new URLSearchParams(window.location.search);
              for (const [key, value] of currentParams.entries()) {
                if (!(key in urlParams) && !(value)) {
                  urlParams[key] = value;
                }
              }
              
              
              const queryParams = new URLSearchParams(urlParams).toString();

              let url = process.env.VUE_APP_BASE_URL.replace(/\/$/, "") + '/';
                  // url = (process.env.VUE_APP_FOLDER && (process.env.VUE_APP_FOLDER.length !== 0)) ? url+process.env.VUE_APP_FOLDER+'/' : url;

              switch(val) {
                  case 'PersonalDetails':
                      url = url+'personal-details' 
                      break
                  case 'ViewQuotes':
                      url = url+'view-quotes' 
                      break
                  default:
                      url = url+'personal-details'
                      break;
              }
              url = url+'?'+queryParams;
              return window.location.href = url;
            }
        return {
          redirect
        }
      },
});
